<template>
  <div>
    <div class="blockNews" v-if="news.landingInfo && !loading">
      <news-template :news="news" />
      <v-container>
        <v-btn outlined to="/" depressed rounded x-large color="primary" class="my-3">Back</v-btn>
      </v-container>
    </div>
    <div v-if="loading" class="blockNews">
      <v-skeleton-loader type="heading" />
      <v-skeleton-loader
        class="mt-5"
        type="paragraph,paragraph,paragraph,paragraph,paragraph,paragraph,paragraph,paragraph,paragraph,paragraph,paragraph,paragraph,paragraph,paragraph,paragraph"
      />
    </div>
  </div>
</template>

<script>
import Landing from "@/layouts/Landing";
import { getLandingInfo } from "../services/workService";
import config from "../config";

import NewsTemplate from "../components/Home/NewsTemplate.vue";

export default {
  components: { NewsTemplate },
  name: "News",
  async created() {
    this.loading = true;
    this.$emit(`update:layout`, Landing);
    const landingInfo = await getLandingInfo(this.$axios, config);

    if (landingInfo.status === 200) {
      this.success = true;
      this.news = landingInfo.data.find((elem) => {
        return elem.infoid === this.infoid;
      });
      if (!this.news) {
        this.$router.push({ name: "Not Found" }).catch((e) => e);
      }
    } else {
      this.success = false;
    }
    this.loading = false;
  },
  computed: {
    infoid() {
      return this.$route.params.infoid;
    }
  },
  data: () => {
    return {
      news: {},
      loading: false
    };
  }
};
</script>
