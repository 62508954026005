<template>
  <div :class="{ 'image-border-radius': borderRadius }">
    <v-img
      :src="src"
      :lazy-src="lazySrc"
      :aspect-ratio="aspectRatio"
      :class="{ grey: !contain, 'lighten-2': !contain }"
      :contain="contain"
      :height="height"
      :width="width"
      :style="{ opacity: opacity }"
    >
      <template v-slot:placeholder>
        <div class="fill-height ma-0 d-flex align-center justify-center">
          <!-- <v-progress-circular :value="time" color="grey lighten-5" v-if="fileDescriptor"></v-progress-circular> -->
          <looping-rhombuses-spinner :animation-duration="2000" :size="60" :color="'white'" v-if="!loadingError" />
          <v-icon v-if="loadingError" large color="white">mdi-image-broken-variant</v-icon>
          <!-- <v-skeleton-loader type="image@3" height="100%"></v-skeleton-loader> -->
        </div>
      </template>
      <slot />
    </v-img>
    <!-- <p>{{ dataLength }}</p> -->
    <!-- <img height="100%" width="100%" :src="src" /> -->
  </div>
</template>

<script>
import config from "@/config";
import { downloadImageFromBFS } from "@/services/fileService";
import { LoopingRhombusesSpinner } from "epic-spinners";

export default {
  components: {
    LoopingRhombusesSpinner
  },
  props: {
    fileDescriptor: {
      default: null,
      type: Object
    },
    height: {
      default: null,
      type: String
    },
    width: {
      default: null,
      type: String
    },
    borderRadius: {
      default: true,
      type: Boolean
    },
    opacity: {
      default: 1,
      type: Number
    },
    aspectRatio: {
      default: "0.75",
      type: String
    },
    thumbnail: {
      default: true,
      type: Boolean
    },
    contain: {
      default: false,
      type: Boolean
    }
  },

  data: function () {
    return {
      src: null,
      lazySrc: null,
      large: false,
      time: 5,
      type: null,
      dataLength: 0,
      thumbnailExist: false,
      loadingError: false
    };
  },
  created() {
    this.loadImage();
  },

  watch: {
    fileDescriptor: function () {
      this.loadImage();
    }
  },
  methods: {
    resizeBase64Img(dataURL, maxWidth, maxHeight) {
      return new Promise((done) => {
        var img = new Image();
        img.onload = () => {
          var scale, newWidth, newHeight, canvas, ctx;
          if (img.width < maxWidth) {
            scale = maxWidth / img.width;
          } else {
            scale = maxHeight / img.height;
          }
          newWidth = img.width * scale;
          newHeight = img.height * scale;
          canvas = document.createElement("canvas");
          canvas.height = newHeight;
          canvas.width = newWidth;
          ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
          done(canvas.toDataURL());
        };
        img.src = dataURL;
      });
    },
    countDownTimer() {
      if (this.time < 100) {
        setTimeout(() => {
          this.time += 1;
          this.countDownTimer();
        }, 10);
      } else {
        this.time = 0;
      }
    },
    async loadImage() {
      this.src = null;
      this.lazySrc = null;
      this.countDownTimer();
      if (this.fileDescriptor === "No image" || !this.fileDescriptor || this.fileDescriptor.fileid === "") {
        this.loadingError = true;
        return;
      }

      if (Object.hasOwnProperty.call(this.fileDescriptor, "thumbnail")) {
        this.thumbnailExist = true;
      }

      if (this.thumbnailExist && this.thumbnail) {
        downloadImageFromBFS(this.$axios, config, this.fileDescriptor.thumbnail).then(async (res) => {
          if (res.status === 200) {
            this.lazySrc = res.data;
            if (this.thumbnail) {
              this.src = res.data;
            }
          } else {
            this.loadingError = true;
          }
        });
      }

      if (!this.thumbnail || !this.thumbnailExist) {
        downloadImageFromBFS(this.$axios, config, this.fileDescriptor).then(async (res) => {
          if (res.status === 200) {
            this.src = res.data;
          } else {
            this.loadingError = true;
          }
        });
      }
    }
  }
};
</script>
