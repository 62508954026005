<template>
  <v-container>
    <image-from-file-descriptor
      :fileDescriptor="news.landingInfo.coverImage"
      :borderRadius="false"
      :thumbnail="false"
      aspectRatio=""
      style="margin-bottom: 10px"
      v-if="!news.landingInfo.hideCoverImage && news.landingInfo.coverImage"
    />
    <div>
      <h3>
        {{ news.landingInfo.title[$i18n.locale] }}
      </h3>
      <p class="sb">
        {{ new Date(news.createdAt).toISOString().substr(0, 10) }}
      </p>
    </div>
    <v-divider class="my-5"></v-divider>
    <div class="newsContent" v-html="news.landingInfo.body[$i18n.locale]" />
  </v-container>
</template>

<script>
import ImageFromFileDescriptor from "../Common/ImageFromFileDescriptor.vue";

export default {
  components: { ImageFromFileDescriptor },
  name: "NewsTemplate",
  props: {
    news: {
      type: Object,
      required: true
    }
  },
  computed: {
    infoid() {
      return this.$route.params.infoid;
    }
  }
};
</script>
